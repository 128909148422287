






































import { Component, Vue, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { namespace } from "vuex-class";
import PreExamsListAnswers from "@/views/Student/LearnStatus/PreExamsList/PreExamsListAnswers.vue";
import { getFile } from "@/utils/File";

const TheoryPreExamsModule = namespace("theory-pre-exams");

@Component({
  components: {
    PreExamsListAnswers,
    FscSimpleCard,
  },
})
export default class PreExamsListQuestions extends Vue {
  public name = "PreExamsListQuestions";

  @TheoryPreExamsModule.Action("theoryPreExamById")
  public findTheoryPreExamById: any;

  @TheoryPreExamsModule.Getter("getDataItem")
  public theoryPreExam: any;

  @Prop()
  public preExam!: any;

  public generalQuestions: any = [];
  public licenseClassQuestions: any = [];
  public preExamAnswers: any = [];
  public selectedQuestion: any = null;
  public selectedAnswer: any = null;
  public licenseClassName = "";

  public async mounted(): Promise<any> {
    this.selectedQuestion = null;
    this.selectedAnswer = null;
    const params = this.$route.params;
    let educationName = params.class;
    this.licenseClassName = educationName;
    await this.findTheoryPreExamById(Number(params.id));

    const theoryPreExamQuestions = this.theoryPreExam ? this.theoryPreExam.theoryQuestions : [];

    const theoryPreExamAnswers = this.theoryPreExam ? this.theoryPreExam.answers : [];

    this.preExamAnswers = theoryPreExamAnswers;

    if (theoryPreExamQuestions.length > 0) {
      this.generalQuestions = theoryPreExamQuestions.filter((question: any) =>
        this.questionClassSplit(question.questionClass).some((e: any) => e === "G" || e === "GM")
      );
      if (
        educationName === "B" ||
        educationName === "BE" ||
        educationName === "B78" ||
        educationName === "B96" ||
        educationName === "B196" ||
        educationName === "B197"
      ) {
        this.licenseClassQuestions = theoryPreExamQuestions.filter((question: any) =>
          this.questionClassSplit(question.questionClass).some((e: any) => e === "B")
        );
      } else {
        this.licenseClassQuestions = theoryPreExamQuestions.filter((question: any) =>
          this.questionClassSplit(question.questionClass).some((e: any) => e === educationName)
        );
      }
    } else {
      this.generalQuestions = [];
      this.licenseClassQuestions = [];
    }
  }

  public generalQuestionsNumber(index: any) {
    return index + 1;
  }

  public licenseClassQuestionsNumber(index: any, questions: Array<any>) {
    return index + (questions.length + 1);
  }

  public onGeneralQuestionClick(question: any): void {
    this.selectedQuestion = question;
    this.selectedAnswer = this.preExamAnswers.filter((answer: any) => answer.theoryQuestionId === question.id);
  }

  public onLicenseClassQuestionClick(question: any): void {
    this.selectedQuestion = question;
    this.selectedAnswer = this.preExamAnswers.filter((answer: any) => answer.theoryQuestionId === question.id);
  }

  public questionClassSplit(questionClass: any) {
    if (questionClass) {
      const singleClassArray = questionClass.split(",");
      return singleClassArray;
    }
  }

  public questionClassStyle(question: any) {
    const result = this.preExamAnswers.filter((answer: any) => answer.theoryQuestionId === question.id);
    if (result[0].correct) {
      return `fsc-btn bg-success`;
    }
    return "fsc-btn bg-danger";
  }

  // @Watch("preExam", { immediate: true, deep: true })
  // public async onPreExamChange(preExam: any): Promise<void> {
  //   if (preExam) {
  //     this.selectedQuestion = null;
  //     this.selectedAnswer = null;
  //     const educationName = preExam.studentEducation.name;
  //     await this.findTheoryPreExamById(preExam.id);
  //
  //     const theoryPreExamQuestions = this.theoryPreExam ? this.theoryPreExam.theoryQuestions : [];
  //
  //     const theoryPreExamAnswers = this.theoryPreExam ? this.theoryPreExam.answers : [];
  //
  //     this.preExamAnswers = theoryPreExamAnswers;
  //
  //     if (theoryPreExamQuestions.length > 0) {
  //       this.generalQuestions = theoryPreExamQuestions.filter((question: any) =>
  //         this.questionClassSplit(question.questionClass).some((e: any) => e === "G" || e === "GM")
  //       );
  //
  //       this.licenseClassQuestions = theoryPreExamQuestions.filter((question: any) =>
  //         this.questionClassSplit(question.questionClass).some((e: any) => e === educationName)
  //       );
  //     } else {
  //       this.generalQuestions = [];
  //       this.licenseClassQuestions = [];
  //     }
  //   }
  // }
}
